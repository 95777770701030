/* Mobile */
@media screen and (max-width: 500px) {
    .Header {
        padding: 0 25px !important;
    }

    .Header-for-search{
        padding: 0 10px !important;
        gap: 10px !important;
    }

    .Header-right-gr{
        gap: 20px !important;
    }

    .Banner {
        padding: 10px !important;
    }

    .Banner>h2 {
        font-size: 17px;
    }

    .import_date {
        padding: 0 20px !important;
    }

    .About {
        padding: 40px 20px !important;
    }

    .Committee-content,
    .TravelInformation {
        padding: 30px 15px !important;
    }

    .Committee_item>p {
        padding-left: 15px !important;
    }

    .Committee_item div p {
        padding-left: 15px !important;
    }

    .List_Slide_stital {
        font-size: 18px !important;
        padding  : 0 20px;
        bottom: 25px !important;
    }

    .List_Slide_logo{
        font-size: 23px !important;
        padding: 0 15px !important;
    }

    .Slide_video_fr {
        height: 350px !important;
    }

    .program {
        padding: 0 15px !important;
    }

    .schedule ul {
        padding-left: 10px !important;
    }

    .TravelInformation_item_img {
        height: 250px !important;
    }

    .Footer {
        height: 200px !important;
    }

    .Footer_info {
        flex-direction: column;
        gap           : 0;
    }

    .ContactUs {
        padding: 15px !important;
    }

    .TravelInformation_item-plug {
        gap   : 15px !important;
        margin: 10px 0 !important;
    }
}

@media screen and (max-width: 1000px) {
    .About {
        grid-template-areas: "about"
            "img"
            "topic" !important;
        grid-template-rows   : auto 1fr;
        grid-template-columns: 1fr !important;
    }

    .Speakers {
        width: 80% !important;
    }

    .import_date {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
    }

    .Contact_left {
        flex-direction: column !important;
        gap           : 20px !important;
    }

    .contact_item {
        width: 100% !important;
    }

    .contact_image {
        width: 100% !important;
    }

}

/* Tablet */
@media screen and (max-width: 1100px) {

    .Header-name {
        display: block !important;
    }

    .Header-left {
        gap: 0;
    }

    .Header-logo {
        margin-right: 15px !important;
    }

    .Header-right {
        /* display: none; */
    }

    .Header-tab {
        display: none;
    }

    .Header-mobile-right {
        display: block !important;
    }

    .Committee-content,
    .TravelInformation {
        flex-direction: column;
        gap           : 0 !important;
    }

    .Committee_right,
    .TravelInformation_right {
        width: 100% !important;
    }
}