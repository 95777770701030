.Header{
    display: flex;
    padding: 0 50px;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: #07223C;
    gap: 20px;

}

.Header-left{
    display: flex;
    align-items: center;
    height: 100%;
}

.Header-name{
    display: none;
    font-size: 20px;
    font-weight: 700;
    color: white;
    width: fit-content;
    margin-left: 10px;
}

.Header-right i, .Header-back i{
    cursor: pointer;
    color: white;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
}

.Header-right i:hover, .Header-back i:hover{
    background-color: rgba(255, 255, 255, 0.1);
}

.Header-logo{
    display: flex;
    margin-right: 30px;
    align-items: center;
}

.Header-link{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0 25px;
    font-weight: 500;
    font-size: 16px;
    border: 4px solid transparent;
}

.Header-link:hover{
    background-color: #f8f046;
    color:  #07223C;;
}

.Heade-active{
    background-color: #f8f046;
    color:  #07223C;;
    font-weight: 600;
}

.Header-tab{
    position: relative;
    height: 100%;
    cursor: pointer;
}

.Header-tab_child{
    position: absolute;
    background-color: white;
    top: 70px;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
    transition-delay: 0.1s;
    width: 200px;
    z-index: 99;
    box-shadow: 0px 0px 10px #00000033;
}

.Header-tab_child::before{
    position: absolute;
    top: -3px;
    left: 20px;
    content: "";
    width: 30px;
    height: 30px;
    background-color: white;
    transform: rotate(45deg);
    z-index: -1;
}

.Header-tab:hover .Header-tab_child{
    visibility: visible;
    opacity: 1;
}

.Header-tab_child a{
    color: black;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    padding: 13px 15px;
    border-bottom: 1px solid rgba(170, 170, 170, 0.263);
}

.Header-group{
    display: flex;
    align-items: center;
    gap: 10px;
}

.Heade-group-active{
    border-bottom: 4px solid #ffde17;
}

.Header-middle{
    height: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    max-width: 700px;
}

.Header-middle > input{
    height: 38px;
    font-weight: 500;
    font-size: 15px;
    padding-left: 10px;
    animation: animation-for-search 0.2s ease-in-out;
    width: 100%;
}

@keyframes animation-for-search {
    0%{
        width: 0%;
    }

    100%{
        width: 100%;
    }
}

.Header-right-gr{
    display: flex;
    align-items: center;
    gap: 30px;
}

.Header-mobile-right{
    display: none;
}

.Header-mobile-right i{
    color: white;
    font-size: 25px;
}

.ant-drawer-body{
    padding: 10px !important;
}